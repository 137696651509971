@use '@angular/material' as mat;

@mixin vp-datepicker-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);

    .vp-datepicker-panel-class {
        &.mat-calendar {
            width: 384px !important;
        }

        .mat-calendar-body-label {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            line-height: 0;
            visibility: hidden;
        }
        .mat-calendar-table-header-divider {
            visibility: hidden;
        }

        // default cell style
        .mat-calendar-body-cell:hover:not(.mat-calendar-body-disabled):not(.mat-calendar-body-active) {
            .mat-calendar-body-cell-content {
                background-color: mat.m2-get-color-from-palette($primary, 50) !important;
            }
        }
        .mat-calendar-body-cell-content {
            font-size: 16px;
            width: 100%;
            height: 100%;
            background-color: mat.m2-get-color-from-palette($primary, A100) !important;
        }
        // selected cell style
        .mat-calendar-body-active {
            .mat-calendar-body-selected {
                background-color: mat.m2-get-color-from-palette($primary, 900) !important;
            }
            &:hover {
                .mat-calendar-body-selected {
                    background-color: mat.m2-get-color-from-palette($primary, 500) !important;
                }
            }
        }
        // today cell style
        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
            border-width: 2px;
            border-color: mat.m2-get-color-from-palette($primary, 900);
        }
    }

    .mat-datepicker-content {
        position: absolute;
        top: 4px;
    }
}
