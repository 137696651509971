@use 'sass:map';
@use '@angular/material' as mat;

@mixin vp-global-spinner-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary-palette: map.get($color-config, 'primary');

    vp-global-spinner {
        .global-spinner {
            background-color: rgba(mat.m2-get-color-from-palette($primary-palette, 100), 0.75);
        }
    }
}
