@mixin vp-slider-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);

    .vp-slider {
        .mat-ripple {
            display: none;
        }

        .mdc-slider__thumb-knob {
            border-width: 2px !important;
            border-color: mat.m2-get-color-from-palette($primary) !important;
            background-color: mat.m2-get-color-from-palette($primary, A100) !important;
        }
    }
}

.vp-living-area-slider {
    .mat-mdc-slider {
        width: 66%;
    }
}
