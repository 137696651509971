@use '@angular/material' as mat;
@import 'variables';
@import 'breakpoints';
@import 'mixins';

@mixin vp-radio-error($color) {
    border-color: mat.m2-get-color-from-palette($color);
    transition: border-color 0ms;
    outline: mat.m2-get-color-from-palette($color) solid 1px;
    outline-offset: -2px;
}

@mixin vp-radio-disabled($color) {
    &.vp-radio-disabled {
        .mat-internal-form-field:hover {
            border-color: mat.m2-get-color-from-palette($color, 300);
        }

        .mdc-radio__outer-circle {
            background-color: mat.m2-get-color-from-palette($color, 50);
        }
    }
}

@mixin vp-radio-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);
    $warn: map-get($color-config, warn);
    mat-radio-group {
        .mat-mdc-radio-button {
            display: inline-block;
            cursor: pointer;
        }

        .mat-internal-form-field {
            border: 1px solid mat.m2-get-color-from-palette($primary, 300);
            align-items: flex-start !important;
            width: 100%;
            box-sizing: border-box;
            border-radius: $border-radius-6;
            transition: border-color $transition-150;
            .mdc-radio__background::before {
                display: none;
            }
            .mdc-radio {
                padding: 13px 10px 13px 18px;
            }

            &:hover {
                border-color: mat.m2-get-color-from-palette($accent);
            }
        }
        .mat-mdc-radio-checked {
            .mat-internal-form-field {
                border-color: mat.m2-get-color-from-palette($primary) !important;
            }
        }

        &.ng-touched.ng-invalid {
            .mat-internal-form-field {
                @include vp-radio-error($warn);
            }
        }

        .mdc-form-field {
            label {
                padding: 11px 36px 11px 0;
                cursor: pointer;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                // 48px is radio's circle width
                width: calc(100% - 48px);
            }
        }

        &.vp-radio-no-circle {
            .mdc-radio {
                display: none;
            }
            .mdc-form-field {
                label {
                    padding: 14px 18px;
                    align-items: center;
                    justify-content: center;
                }
            }
            vp-icon {
                order: 0;
                color: mat.m2-get-color-from-palette($primary, 300);
            }
            .mat-mdc-radio-button {
                color: mat.m2-get-color-from-palette($accent);
                transition: color $transition-150;
                &:hover {
                    vp-icon {
                        color: mat.m2-get-color-from-palette($accent);
                    }
                }
                &.mat-mdc-radio-checked {
                    color: mat.m2-get-color-from-palette($primary);
                    vp-icon {
                        color: mat.m2-get-color-from-palette($primary);
                    }
                }
            }
        }
        &.vp-radio-thick {
            .mat-internal-form-field {
                display: block;
                border-width: 2px;
                vp-icon {
                    order: 0;
                    margin-right: 8px;
                }
            }
        }
        .vp-radio-with-image {
            .mdc-form-field {
                label {
                    flex-direction: column;
                    padding: 14px 18px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .vp-radio-with-icon {
            .mdc-form-field {
                label {
                    justify-content: space-between;
                    flex-direction: row;
                    padding-right: 18px;
                    vp-icon {
                        order: 1;
                    }
                }
            }
        }
    }

    .vp-custom-answer-radio {
        mat-radio-group {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            @media screen and (min-width: $md) {
                flex-direction: row;
            }
            .vp-custom-radio-error {
                .mat-internal-form-field {
                    @include vp-radio-error($warn);
                }
            }
            @include vp-radio-disabled($primary);
        }

        mat-radio-button {
            box-sizing: border-box;
            margin-bottom: 16px;

            @media screen and (min-width: $md) {
                width: 50%;
                &:first-of-type {
                    padding-right: $column-gap;
                }
                &:nth-of-type(2) {
                    padding-left: $column-gap;
                }
                &:nth-of-type(3) {
                    padding-right: $column-gap;
                    margin-bottom: 0;
                }
            }
            @include vp-radio-disabled($primary);
        }
        mat-form-field {
            width: 100%;
            .mat-mdc-form-field-flex {
                width: 100%;
            }
            @media screen and (min-width: $md) {
                box-sizing: border-box;
                width: 50%;
                padding-left: $column-gap;
            }
        }
    }
}

.vp-radio-columns {
    display: flex;
    flex-direction: column;
    @media (min-width: $sm) {
        display: inline;
    }
    .mat-mdc-radio-button {
        @include two-columns();
        .mat-internal-form-field {
            width: 100%;
        }
    }
}

.vp-radio-single-column {
    display: flex;
    flex-direction: column;
    .mat-mdc-radio-button {
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
        @media screen and (min-width: $sm) {
            width: calc(50% - $column-gap);
        }
    }
}

.vp-radio-columns-narrow-gap {
    .mat-mdc-radio-button {
        @include two-columns(4px);
        .mat-internal-form-field {
            width: 100%;
        }
    }
}

.vp-radio-margin {
    .mat-mdc-radio-button {
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.vp-radio-width-260 {
    .mat-mdc-radio-button {
        width: auto;
        @media screen and (min-width: $sm) {
            width: 260px;
        }
    }
}

.vp-radio-width-400 {
    .mat-mdc-radio-button {
        width: auto;
        @media screen and (min-width: $sm) {
            width: 400px;
        }
    }
}

.vp-no-border-radio {
    .mat-internal-form-field {
        padding: 0;
        border: 0 none;
    }
}

.vp-variant-modal-radio {
    .mat-internal-form-field {
        .mdc-radio {
            padding: 6px 10px 6px 0px;
            input {
                height: 20px;
                width: 20px;
                top: 6px;
            }
            .mat-mdc-radio-touch-target {
                height: 20px;
                width: 20px;
                left: 10px;
            }
        }
    }
    .mdc-form-field {
        label {
            padding: 4px 36px 4px 0;
        }
    }
}

.vp-radio-button-no-content {
    .mat-internal-form-field .mat-radio-label-content {
        display: none;
    }
}
