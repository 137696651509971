@mixin vp-checkbox-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);
    $warn: map-get($color-config, warn);

    .vp-checkbox {
        margin-left: -10px;
        .mdc-checkbox__ripple {
            display: none;
        }
        .mdc-checkbox__background {
            width: 20px !important;
            height: 20px !important;
            border-radius: 4px;
        }
        svg.mdc-checkbox__checkmark {
            transform: scale(0.7);
        }
        .mdc-checkbox__background {
            background-color: transparent !important;
        }
        svg.mdc-checkbox__checkmark {
            color: mat.m2-get-color-from-palette($black-palette, 900) !important;
        }
        .mdc-checkbox__mixedmark {
            margin: 0 2px;
            border-color: unset !important;
        }
        &.mat-mdc-checkbox-disabled {
            .mdc-checkbox__background {
                background-color: mat.m2-get-color-from-palette($primary, 50) !important;
            }
            svg.mdc-checkbox__checkmark {
                color: mat.m2-get-color-from-palette($primary, 300) !important;
            }
        }
    }
}

.vp-ventilation-and-heating {
    .mat-mdc-checkbox:nth-child(3),
    .mat-mdc-checkbox:nth-child(5) {
        margin-left: -2px;
    }
}

.vp-street-name-available {
    margin-top: -8px;
}
