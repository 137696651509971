@mixin vp-select-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);

    .mat-mdc-select-panel {
        @include scrollbar-vertical();

        .mat-pseudo-checkbox-minimal {
            display: none;
        }

        .mat-pseudo-checkbox {
            width: 20px;
            height: 20px;
            border-radius: 4px;
            &::after {
                transform: scale(0.9) rotate(-45deg);
            }
        }
    }

    .vp-dropdown-panel-class,
    .vp-multiselect-dropdown-panel-class,
    .vp-search-dropdown-panel-class {
        position: absolute;
        top: 28px;
        left: 4px;

        .mat-active,
        .mat-option:hover:not(.mat-option-disabled),
        .mat-option:focus:not(.mat-option-disabled) {
            background: map-get($primary, 200);
        }
    }

    .vp-multiselect-dropdown-panel-class {
        left: 29px;
        &.mat-primary {
            .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
                border-color: map-get($primary, 900);
                background-color: transparent;
                &::after {
                    color: map-get($primary, 900);
                }
            }
        }
    }

    .vp-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 340px;
        }

        .mat-option {
            height: auto !important;
        }
    }

    .vp-zip-city-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 336px;
        }
    }

    .vp-partner-id-search-dropdown-panel-class {
        &.mat-select-panel {
            max-height: 416px;
        }
    }

    .vp-text-field-dropdown-input {
        z-index: 1;
    }

    .vp-text-field-dropdown {
        .mat-select-value-text,
        .mat-select-arrow-wrapper {
            visibility: hidden;
        }
    }

    // Sets dropdown arrow to the vertical center
    .mat-form-field {
        &.vp-form-field-thin.mat-form-field-appearance-outline {
            .mat-select-arrow-wrapper {
                transform: translateY(0);
            }
        }
    }

    .mat-select-value {
        color: map-get($primary, 900);
    }

    .mat-select-placeholder {
        color: map-get($primary, 500);
    }
}
