@use '@angular/material' as mat;
@import 'variables';

@mixin vp-button-basic() {
    box-shadow: none;
    border-radius: 50px !important;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 1.29 !important;
    padding: 11px 28px;
    height: 40px;
    transition: background-color $transition-150, border-color $transition-150;

    &-xs {
        font-size: 14px !important;
        padding: 7px 19px;
        height: 32px;
        min-width: 48px !important;
    }

    &-s {
        font-size: 14px !important;
        padding: 11px 28px;
        height: 40px;
    }

    &-m {
        padding: 15px 36px;
        font-size: 16px !important;
        line-height: 1.13 !important;
        height: 48px;
    }

    &-l {
        padding: 19px 36px;
        font-size: 16px !important;
        line-height: 1.13 !important;
        height: 56px;
    }

    &-no-padding {
        padding: 0;
        height: auto;
    }

    $space: 8px;

    vp-icon + span {
        margin-left: $space;
    }

    span + vp-icon {
        margin-left: $space;
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 $space;

        span {
            display: block;
        }

        > mat-icon {
            margin-right: 0.5rem;
        }
    }
}

@mixin vp-button-link() {
    border: none !important;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 1.29 !important;
    padding: 10px 12px;
    letter-spacing: normal;

    $space: 8px;

    vp-icon + span {
        margin-left: $space;
    }

    span + vp-icon {
        margin-left: $space;
    }

    .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            display: block;
        }
    }
}

@mixin vp-buttons-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);

    button[mat-flat-button],
    button[mat-raised-button],
    a[mat-flat-button] {
        &.vp-button {
            @include vp-button-basic();

            &:hover {
                background-color: mat.m2-get-color-from-palette($accent);
            }

            &:focus {
                background-color: mat.m2-get-color-from-palette($primary, 600);
            }

            &:disabled {
                background-color: mat.m2-get-color-from-palette($primary, 100) !important;
                color: mat.m2-get-color-from-palette($primary, 400) !important;
            }
        }
    }

    button[mat-button],
    a[mat-button] {
        &.vp-button {
            @include vp-button-basic();

            &:hover {
                color: mat.m2-get-color-from-palette($accent, 500);
                --mat-text-button-state-layer-color: transparent !important;

                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:disabled {
                color: mat.m2-get-color-from-palette($primary, 300);
            }
        }
    }

    button[mat-raised-button] {
        &.mat-raised-button {
            @include vp-button-basic();

            font-size: 14px !important;
            padding: 8px 24px;
            font-weight: 500;
        }
    }

    button[mat-stroked-button],
    a[mat-stroked-button] {
        border-color: mat.m2-get-color-from-palette($primary, 300);

        &.vp-button {
            @include vp-button-basic();

            &:hover {
                --mat-outlined-button-state-layer-color: transparent !important;
                border-color: mat.m2-get-color-from-palette($primary, 400);

                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:focus {
                border-color: mat.m2-get-color-from-palette($primary);
            }

            &:disabled {
                border-color: mat.m2-get-color-from-palette($primary, 300);
                color: mat.m2-get-color-from-palette($primary, 300);
            }
        }
    }

    button[mat-icon-button] {
        &.vp-button {
            display: flex;
            justify-content: center;
            align-items: center;

            .mat-mdc-button-persistent-ripple {
                display: none;
            }

            &-s {
                width: 25px;
                height: 25px;
                .mat-mdc-button-touch-target {
                    width: 25px;
                    height: 25px;
                }
            }

            .mat-button-wrapper {
                display: flex;
                justify-content: center;
            }

            .mat-button-focus-overlay {
                display: none;
            }
        }
    }

    button[mat-stroked-button],
    a[mat-button] {
        &.vp-button-link {
            @include vp-button-link();

            &:hover {
                --mat-outlined-button-state-layer-color: transparent !important;
                color: mat.m2-get-color-from-palette($primary, 500) !important;

                .mat-button-focus-overlay {
                    display: none;
                }

                span {
                    text-decoration: underline;
                }
            }

            &:focus {
                color: mat.m2-get-color-from-palette($primary, 600) !important;

                .mat-button-focus-overlay {
                    display: none;
                }
            }

            &:disabled {
                color: mat.m2-get-color-from-palette($primary, 300) !important;

                .mat-button-wrapper {
                    span {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
