@use '@angular/material' as mat;
@import 'breakpoints';
@import 'palette';

$vp-table-typography: mat.m2-define-typography-config(
    $font-family: 'Inter',
    $body-1: mat.m2-define-typography-level(16px, 24px, 400),
    $body-2: mat.m2-define-typography-level(16px, 24px, 400),
    $caption: mat.m2-define-typography-level(18px, 24px, 500)
);

$vp-table-typography-sm: mat.m2-define-typography-config(
    $font-family: 'Inter',
    $body-1: mat.m2-define-typography-level(14px, 21px, 400),
    $body-2: mat.m2-define-typography-level(14px, 21px, 400),
    $caption: mat.m2-define-typography-level(16px, 24px, 500)
);

$vp-typography-sm: mat.m2-define-typography-config(
    $font-family: 'Inter',
    $headline-5: mat.m2-define-typography-level(24px, 32px, 500),
    $headline-6: mat.m2-define-typography-level(20px, 28px, 500),
    $subtitle-1: mat.m2-define-typography-level(18px, 26px, 500),
    $subtitle-2: mat.m2-define-typography-level(16px, 24px, 500),
    $body-1: mat.m2-define-typography-level(16px, 24px, 400),
    $body-2: mat.m2-define-typography-level(16px, 24px, 400)
);

$vp-typography-md: mat.m2-define-typography-config(
    $font-family: 'Inter',
    $headline-5: mat.m2-define-typography-level(32px, 44px, 500),
    $headline-6: mat.m2-define-typography-level(24px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(20px, 28px, 500),
    $subtitle-2: mat.m2-define-typography-level(18px, 24px, 500),
    $body-1: mat.m2-define-typography-level(16px, 24px, 400),
    $body-2: mat.m2-define-typography-level(16px, 24px, 400)
);

$vp-typography-lg: mat.m2-define-typography-config(
    $font-family: 'Inter',
    $headline-5: mat.m2-define-typography-level(32px, 44px, 500),
    $headline-6: mat.m2-define-typography-level(24px, 32px, 500),
    $subtitle-1: mat.m2-define-typography-level(20px, 28px, 500),
    $subtitle-2: mat.m2-define-typography-level(18px, 24px, 500),
    $body-1: mat.m2-define-typography-level(16px, 24px, 400),
    $body-2: mat.m2-define-typography-level(16px, 24px, 400)
);

@media screen and (max-width: $md) {
    @include mat.all-component-typographies($vp-typography-sm);
    @include mat.table-typography($vp-table-typography-sm);

    .vp-h4 {
        font-family: 'Inter', sans-serif !important;
        font-size: 16px !important;
        line-height: 24px !important;
        font-weight: 500 !important;
    }

    .vp-h5 {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .vp-h6 {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .text-small {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
    }

    .text-small-bolder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
    }

    .text-smaller {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    .text-large {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
    }
}

@media screen and (min-width: $md) {
    @include mat.all-component-typographies($vp-typography-md);
    @include mat.table-typography($vp-table-typography);

    .vp-h4 {
        font-family: 'Inter', sans-serif !important;
        font-size: 18px !important;
        line-height: 24px !important;
        font-weight: 500 !important;
    }

    .vp-h5 {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .vp-h6 {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .text-small {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
    }

    .text-small-bolder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
    }

    .text-smaller {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    .text-large {
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        line-height: 44px;
        font-weight: 500;
    }
}

@media screen and (min-width: $lg) {
    @include mat.all-component-typographies($vp-typography-lg);
    @include mat.table-typography($vp-table-typography);

    .vp-h4 {
        font-family: 'Inter', sans-serif !important;
        font-size: 18px !important;
        line-height: 24px !important;
        font-weight: 500 !important;
    }

    .vp-h5 {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .vp-h6 {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        margin: 0 0 12px;
    }

    .text-small {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 400;
    }

    .text-small-bolder {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        line-height: 21px;
        font-weight: 600;
    }

    .text-smaller {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
    }

    .text-large {
        font-family: 'Inter', sans-serif;
        font-size: 32px;
        line-height: 44px;
        font-weight: 500;
    }
}

a {
    color: mat.m2-get-color-from-palette($black-palette, 900);
}

.vp-bold {
    font-weight: 600;
}
