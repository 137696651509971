@use '@angular/material' as mat;
@import 'breakpoints';
@import 'mixins';
@import 'palette';

@mixin outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__leading,
            &__notch,
            &__trailing {
                @content;
            }
        }
    }
}
@mixin leading-outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__leading {
                @content;
            }
        }
    }
}

@mixin trailing-outline-override() {
    .mdc-notched-outline {
        .mdc-notched-outline {
            &__trailing {
                @content;
            }
        }
    }
}

@mixin form-field-width($width) {
    width: $width;

    .mat-mdc-form-field-flex {
        width: 100%;
    }
}

@mixin form-field-states($primary, $accent) {
    &.mat-form-field-appearance-outline {
        .mat-mdc-form-field-flex {
            @include outline-override() {
                transition: $transition-150 border-color;
                border-color: mat.m2-get-color-from-palette($primary, 300);
                border-top-width: 1px !important;
                padding-top: 1px !important;
                border-top-style: solid !important;
            }
            &:hover {
                @include outline-override() {
                    border-color: mat.m2-get-color-from-palette($primary, 400);
                    border-top-width: 1px !important;
                    padding-top: 1px !important;
                    border-top-style: solid !important;
                }
            }
            &:focus-within {
                @include outline-override() {
                    border-color: mat.m2-get-color-from-palette($accent);
                    padding-top: 0 !important;
                    border-top-width: 1px !important;
                    border-bottom-width: 1px !important;
                    border-top-style: solid !important;
                }
                @include leading-outline-override() {
                    border-left-width: 1px !important;
                }
                @include trailing-outline-override() {
                    border-right-width: 1px !important;
                }
            }
        }
        // TODO: Refactor disabled style
        &.mat-form-field-disabled {
            .mat-mdc-form-field-flex {
                @include outline-override() {
                    border-color: mat.m2-get-color-from-palette($primary, 300);
                    background-color: mat.m2-get-color-from-palette($primary, 50);
                }
                .mat-mdc-form-field-infix {
                    z-index: 1;
                }
            }
        }
    }
}

@mixin wrapped-subscript-message($container-width) {
    .mat-mdc-form-field-error,
    .mat-mdc-form-field-hint {
        width: $container-width;
        white-space: normal;
    }
}

@mixin vp-form-field-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);
    $warn: map-get($color-config, warn);

    .mat-mdc-form-field {
        .mat-icon {
            line-height: 1 !important;
            font-size: inherit !important;
        }

        .mat-mdc-form-field-flex {
            width: 230px;
        }

        @include form-field-states($accent, $primary);

        &.mat-form-field-invalid:not(.mat-form-field-disabled) {
            @include form-field-states($warn, $warn);
        }

        &.mat-form-field-appearance-outline {
            .mat-mdc-text-field-wrapper {
                padding: 0 12px 0 12px !important;
            }
        }

        input {
            caret-color: mat.m2-get-color-from-palette($primary);
            color: mat.m2-get-color-from-palette($primary, 900);

            &::placeholder {
                color: mat.m2-get-color-from-palette($primary, 500);
            }
        }

        &.with-wrapped-error-message-350,
        &.with-wrapped-error-message-150,
        &.with-paragraph-error-message,
        &.with-wrapped-error-message,
        &.with-wrapped-hint,
        &.with-hint,
        &.with-error-message {
            .mat-mdc-form-field-subscript-wrapper {
                padding: 0;
                margin-bottom: 5px;
            }
            .mat-mdc-form-field-error-wrapper,
            .mat-mdc-form-field-hint-wrapper {
                padding: 5px 0;
            }

            .mat-mdc-form-field-error,
            .mat-mdc-form-field-hint {
                overflow: visible;
                white-space: nowrap;
            }
        }

        &.with-wrapped-hint {
            @include wrapped-subscript-message(100%);
        }

        &.with-wrapped-error-message {
            @include wrapped-subscript-message(100%);
        }

        &.with-wrapped-error-message-150 {
            @include wrapped-subscript-message(150%);
        }

        &.with-wrapped-error-message-350 {
            @include wrapped-subscript-message(350%);
        }

        &.with-label {
            .mat-mdc-floating-label {
                left: 0;
            }

            .mdc-floating-label--float-above {
                transform: matrix(0.75, 0, 0, 0.75, 0, -18) !important;
            }

            .mat-mdc-form-field-infix {
                padding: 26px 0 6px !important;
            }
        }

        &.vp-form-field-thin {
            .mat-mdc-form-field-flex {
                height: 40px;
            }
            .mat-mdc-form-field-infix {
                padding: 8px 0 !important;
            }
        }

        &.vp-form-field-w-100 {
            @include form-field-width(100%);
        }
        // TODO: Check slider styles
        &.vp-form-field-slider {
            .mat-mdc-form-field-flex {
                width: 100%;
            }
        }

        &.vp-form-field-short {
            @include form-field-width(180px);
        }

        &.vp-form-field-w-65 {
            @include form-field-width(65px);
        }

        &.vp-form-field-w-77 {
            @include form-field-width(77px);
        }

        &.vp-form-field-w-75 {
            @include form-field-width(75px);
        }

        &.vp-form-field-w-59 {
            @include form-field-width(59px);
        }

        &.vp-form-field-w-44 {
            @include form-field-width(44px);
        }

        &.vp-form-field-medium {
            .mat-mdc-form-field-flex {
                height: 48px;
            }
            .mat-mdc-form-field-infix {
                padding: 12px 0 !important;
            }
        }

        &.vp-form-field-value {
            @include outline-override() {
                border-color: mat.m2-get-color-from-palette($primary, 900);
            }
        }

        &.with-paragraph-error-message {
            .mat-mdc-form-field-error {
                white-space: wrap;
            }
            .mat-mdc-form-field-subscript-wrapper {
                width: 125px;
                @media screen and (min-width: $md) {
                    width: 200px;
                }
            }
        }

        &.vp-highlight:not(.mat-form-field-invalid) {
            &.mat-form-field-appearance-outline {
                .mat-mdc-form-field-flex {
                    @include outline-override() {
                        border-color: mat.m2-get-color-from-palette($green-palette, 600);
                    }
                    &:hover {
                        @include outline-override() {
                            border-color: mat.m2-get-color-from-palette($green-palette, 600);
                        }
                    }
                    &:active,
                    &:focus-within {
                        @include outline-override() {
                            border-color: mat.m2-get-color-from-palette($green-palette, 600);
                        }
                    }
                }

                .mat-mdc-floating-label,
                .mat-icon {
                    color: mat.m2-get-color-from-palette($green-palette, 600) !important;
                }

                .mat-mdc-form-field-icon-suffix {
                    padding: 16px 0 0 4px;
                }
            }
        }
    }
}

.vp-form-field-location {
    .mat-mdc-form-field {
        box-sizing: border-box;
        width: 23%;

        &.vp-form-field-country-code {
            margin-right: 77%;
        }

        &:nth-of-type(2) {
            width: 77%;
        }

        @media screen and (min-width: $md) {
            width: 30%;

            &.vp-form-field-country-code {
                margin-right: 70%;
            }

            &:nth-of-type(2) {
                width: 70%;
            }
        }

        .mat-mdc-form-field-flex {
            width: 100%;
        }
    }
}

.vp-form-field-columns {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .mat-mdc-form-field {
        @include two-columns(8px, 0px);

        .mat-mdc-form-field-flex {
            width: 100%;
        }
    }
}
