@use '@angular/material' as mat;
@import 'material-icons/iconfont/material-icons.css';
@import './styles/theme.scss';
@import 'typography';
@import 'breakpoints';
@import 'spacing';
@import 'variables';
@import 'flags';

$enable-cssgrid: true;
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/dist/css/bootstrap-utilities.min.css';

html {
    min-height: 100%;
    height: 100%;
}

body {
    height: 100%;
    min-height: 100vh;
    background-color: mat.m2-get-color-from-palette($black-palette, 50);
    color: mat.m2-get-color-from-palette($black-palette, 900);
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;

    // Overwrite global header styles till @vi/global-header uses Angular Material v15
    // START
    .app-switcher-panel {
        .mat-mdc-menu-content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .mat-mdc-menu-content:not(:empty) {
            padding-top: 0;
            padding-bottom: 0;
        }

        .menu-footer .mat-icon {
            display: none;
        }
    }

    .app-login {
        button {
            display: flex;
            justify-content: center;
            align-items: center;

            &.login-button {
                background-color: mat.m2-get-color-from-palette($black-palette, 900);
                border-radius: 50px;
                font-weight: 600;
                font-size: 14px;
                line-height: 1.29;
                height: 40px !important;
                letter-spacing: normal;
                padding: 11px 28px;
                margin: 0 !important;
            }
        }
    }

    // END

    .app-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100vh;

        .app-header {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: $navbar-z-index;
        }

        .app-header,
        .app-footer {
            flex-shrink: 0;
        }
    }
}

$navbarHeight: 49px;
$paddingY: 24px;
$paddingTop: $navbarHeight + $paddingY;

vp-navbar + .container {
    &.container-root {
        padding: $paddingTop 16px 24px;

        @media screen and (min-width: $md) {
            padding: $paddingTop 24px 24px;
        }

        @media screen and (min-width: $lg) {
            padding: $paddingTop 104px 32px;
        }
    }

    &.container-root-s {
        padding: $paddingTop 16px 24px;

        @media screen and (min-width: $md) {
            padding: $paddingTop 208px 24px;
        }

        @media screen and (min-width: $lg) {
            padding: $paddingTop 416px 24px;
        }
    }

    &.container-root-m {
        padding: $paddingTop 16px 24px;

        @media screen and (min-width: $md) {
            padding: $paddingTop 104px 24px;
        }

        @media screen and (min-width: $lg) {
            padding: $paddingTop 312px 24px;
        }
    }
}

.container-root {
    padding: $paddingY 16px 24px;

    @media screen and (min-width: $md) {
        padding: $paddingY 24px 24px;
    }

    @media screen and (min-width: $lg) {
        padding: $paddingY 104px 32px;
    }
}

.container-root-s {
    padding: $paddingY 16px 24px;

    @media screen and (min-width: $md) {
        padding: $paddingY 208px 24px;
    }

    @media screen and (min-width: $lg) {
        padding: $paddingY 416px 24px;
    }
}

.container-root-m {
    padding: $paddingY 16px 24px;

    @media screen and (min-width: $md) {
        padding: $paddingY 104px 24px;
    }

    @media screen and (min-width: $lg) {
        padding: $paddingY 312px 24px;
    }
}

.vp-pointer {
    cursor: pointer;
}

.vp-border-box {
    box-sizing: border-box;
}

.vp-error-message {
    color: map-get($salmon-palette, 500);
}

.vp-subscript {
    padding: 0;
    height: 13.5px;
    margin-bottom: 10.5px !important;
    font-size: 12px;
    line-height: 13.5px;
}

vp-search-dropdown {
    .vp-subscript {
        margin-bottom: 0 !important;
    }
}

.vp-truncate-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.vp-negative-margin-bottom-16 {
    margin-bottom: -16px;
}

.vp-negative-margin-top-16 {
    margin-top: -16px;
}

.vp-overflow-hidden {
    overflow: hidden;
}

.vp-border {
    border: 1px solid map-get($black-palette, 200);
    border-radius: $border-radius-6;

    &-hover:hover {
        @include border-dark();
    }
}

.vp-gap-4 {
    gap: 4px;
}

.vp-gap-8 {
    gap: 8px;
}

.vp-gap-12 {
    gap: 12px;
}

.vp-gap-16 {
    gap: 16px;
}

.vp-gap-24 {
    gap: 24px;
}

.vp-gap-32 {
    gap: 32px;
}

.vp-gap-36 {
    gap: 36px;
}

.vp-gap-42 {
    gap: 42px;
}

.vp-gap-48 {
    gap: 48px;
}

.vp-gap-56 {
    gap: 56px;
}

.vp-gap-64 {
    gap: 64px;
}

.vp-no-min-width {
    min-width: unset !important;
}

.vp-w-100 {
    width: 100%;
}

.vp-h-100 {
    height: 100%;
}

.vp-push-right-1 {
    margin-right: -1rem !important;
}

.vp-grid-gap-8 {
    --bs-gap: 8px;
}

.vp-grid-gap-16 {
    --bs-gap: 16px;
}

.vp-cdk-backdrop-pointer-events-none {
    .cdk-overlay-backdrop {
        pointer-events: none;
    }
}

.vp-pre-line {
    white-space: pre-line;
}

.vp-card {
    background-color: map-get($black-palette, A100);
}

.vp-badge {
    padding: 4px 8px;
    margin-right: 4px;
    background-color: map-get($black-palette, 100);
    border-radius: 2px;
    font-weight: 600;
}

.vp-scrollbar-vertical {
    @include scrollbar-vertical();
}

.vp-scrollbar-horizontal {
    @include scrollbar-horizontal();
}
