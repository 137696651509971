@import 'palette';

@mixin vp-snackbar-theme($theme) {
    .vp-snackbar {
        margin-bottom: 5vh !important;

        &.vp-info {
            .mdc-snackbar__surface {
                background-color: map-get($black-palette, 600) !important;
            }

            .mdc-snackbar__label {
                color: map-get($black-palette, A100) !important;
            }

            .mat-mdc-snack-bar-action {
                color: map-get($black-palette, A100) !important;
            }

            .mat-mdc-button {
                color: map-get($black-palette, A100) !important;
            }
        }
    }
}
