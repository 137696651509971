@use '@angular/material' as mat;
@import 'variables';

@mixin vp-chips-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);

    .vp-filter-chip {
        .mat-mdc-chip-focus-overlay {
            display: none;
        }
        &.mat-mdc-chip.mat-mdc-standard-chip {
            background: transparent;
            border: 1px solid mat.m2-get-color-from-palette($primary, 300);

            &:hover {
                background: transparent;
                border-color: mat.m2-get-color-from-palette($primary, 400);
            }

            &:active {
                box-shadow: none;
            }

            &::after {
                display: none;
            }

            .mat-chip-remove,
            .mat-chip-trailing-icon {
                width: 16px;
                height: 16px;
            }
            .mat-icon {
                width: inherit;
                height: inherit;
                font-size: inherit;
            }
        }
    }
}
