@use '@angular/material' as mat;

@mixin vp-tooltip-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);

    .vp-tooltip {
        position: relative;
        overflow: visible !important;
        &:after {
            content: '';
            position: absolute;
            left: calc(50% - 8px);
            bottom: -16px;
            border: 8px solid transparent;
            border-top-color: mat.m2-get-color-from-palette($primary, A100);
        }
        .mdc-tooltip__surface {
            color: mat.m2-get-color-from-palette($primary, 900) !important;
            background-color: mat.m2-get-color-from-palette($primary, A100) !important;
            padding: 8px;
            max-width: 320px;
            line-height: 18px !important;
            box-shadow: 0 4px 8px 0 rgba(17, 17, 17, 0.08), 0 8px 16px 0 rgba(17, 17, 17, 0.08), 0 2px 4px 0 rgba(17, 17, 17, 0.08);
        }
        &.vp-tooltip-long {
            .mdc-tooltip__surface {
                max-width: none;
            }
        }
    }
}
