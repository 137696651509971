@use '@angular/material' as mat;

@mixin vp-accordion-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);

    .mat-expansion-panel {
        box-shadow: none !important;
    }

    .mat-expansion-panel-header,
    .mat-expansion-panel-header:hover {
        background-color: mat.m2-get-color-from-palette($primary, A100) !important;
    }

    .mat-expansion-indicator {
        width: 18px;
        &::after {
            border-color: mat.m2-get-color-from-palette($primary);
            padding: 5px !important;
            transform: rotate(45deg) translateY(-5px) !important;
        }
    }
    .mat-expansion-panel-header-title {
        font-size: 20px;
    }

    .vp-create-report-accordion {
        .mat-expansion-panel-header[aria-disabled='true'] {
            color: map-get($black-palette, 900) !important;
        }
    }
}
