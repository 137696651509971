@mixin vp-carousel-theme($theme) {
    $color-config: mat.m2-get-color-config($theme);
    $primary: map-get($color-config, primary);
    $accent: map-get($color-config, accent);

    .vp-carousel {
        .owl-theme {
            .owl-nav.disabled + .owl-dots {
                margin-top: 20px;
            }
            .owl-dots {
                .owl-dot {
                    span {
                        background: mat.m2-get-color-from-palette($primary, 300);
                        width: 24px;
                        height: 4px;
                        margin: 5px 4px;
                        border-radius: 0;
                    }
                    &.active span {
                        background: mat.m2-get-color-from-palette($primary, 500);
                    }
                }
            }
        }
        .owl-stage {
            display: flex;
            justify-content: stretch;
            &::after {
                content: '';
            }
        }
    }
}
